import React from 'react';
import KakaoChat from './Chat';

function App() {
  return (
    <div className="App">
      <KakaoChat />
    </div>
  );
}

export default App;