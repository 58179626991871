import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import Cookies from 'js-cookie';
import logo from './images/the-prompt-company-logo.webp';

const API_BASE_URL = process.env.REACT_APP_ENV === 'development' 
  ? 'http://localhost:8000' 
  : '/api';
console.log('API_BASE_URL:', API_BASE_URL);

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const messagesEndRef = useRef(null);
  const [key, setKey] = useState(Cookies.get('chatKey') || '');
  const [isKeyModalOpen, setIsKeyModalOpen] = useState(false);
  const chatAreaRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showWelcomePopup, setShowWelcomePopup] = useState(true);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatAreaRef.current && chatAreaRef.current.contains(event.target)) {
        setIsKeyModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const savedKey = Cookies.get('chatKey');
    if (savedKey) {
      setKey(savedKey);
    }
  }, []);

  useEffect(() => {
    // 웹 페이지가 로드될 때 팝업을 표시합니다.
    setShowWelcomePopup(true);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // 웹훅 요청 데이터 준비 함수 수정
  const prepareWebhookPayload = (userInput) => {
    // 현재 메시지를 포함한 전체 대화 히스토리 생성
    const updatedMessages = [
      ...messages,
      { text: userInput, sender: 'user' }
    ];
    
    return {
      timezone: "Asia/Seoul",
      params: {},
      block: { id: "1", name: "질문 블록" },
      utterance: userInput,
      lang: "kr",
      user: {
        id: "user123",
        type: "individual",
        properties: {}
      },
      bot: {
        id: "bot123",
        name: "RAG 챗봇"
      },
      action: {
        name: "질문하기",
        clientExtra: null,
        params: {
          text: userInput
        },
        id: "action123",
        detailParams: {
          text: {
            origin: userInput,
            value: userInput
          }
        }
      },
      messages: updatedMessages.map(msg => ({ role: msg.sender, content: msg.text }))
    };
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== '') {
      if (key !== "prompt") {
        alert("잘못된 키입니다.");
        return;
      }

      const userMessage = { text: inputMessage, sender: 'user' };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setIsLoading(true);

      try {
        const webhookPayload = prepareWebhookPayload(inputMessage);
        console.log('Sending webhook payload:', webhookPayload);
        const response = await axios.post(`${API_BASE_URL}/webhook`, webhookPayload, {
          headers: {
            'Content-Type': 'application/json',
          },
          timeout: 10000, // 10초 타임아웃 설정
        });
        
        console.log('Webhook response:', response);

        if (response.status === 200) {
          const result = response.data;
          const aiMessage = { text: result.answer, sender: 'ai' };
          setMessages((prevMessages) => [...prevMessages, aiMessage]);
        } else {
          console.error('오류 발생:', response.status);
          alert('서버 응답 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.');
        }
      } catch (error) {
        console.error('메시지 전송 오류:', error);
        alert('네트워크 오류가 발생했습니다. 인터넷 연결을 확인하고 다시 시도해 주세요.');
      } finally {
        setIsLoading(false);
      }

      setInputMessage('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
      setInputMessage(''); // 엔터 키를 누르면 즉시 입력 필드를 비웁니다.
    }
  };

  const toggleKeyModal = () => {
    setIsKeyModalOpen(!isKeyModalOpen);
  };

  const clearMessages = () => {
    setMessages([]);
    setInputMessage('');
    setKey('prompt');
    setIsKeyModalOpen(false);
    alert('모든 대화 내용이 초기화되었습니다.');
  };

  const handleKeyChange = (e) => {
    const newKey = e.target.value;
    setKey(newKey);
    Cookies.set('chatKey', newKey, { expires: 7 }); // 7일 동안 유효한 쿠키 설정
  };

  const showAboutPopup = () => {
    setShowWelcomePopup(true);
    setIsKeyModalOpen(false);
  };

  return (
    <div className="flex flex-col h-screen bg-blue-50">
      <style jsx>{`
        .text-gradient {
          background: linear-gradient(45deg, #1a3a5d, #09244d, #051c3d);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      `}</style>
      <div className="bg-blue-400 py-2 px-4 shadow-md flex justify-between items-center">
        <div className="flex items-center">
          <img 
            src={logo} 
            alt="The Prompt Company 로고" 
            className="h-8 w-8 mr-2 object-contain"
          />
          <h1 className="text-xl font-bold text-gradient cursor-pointer" onClick={() => window.open('https://www.theprompt.tech/', '_blank')}>
            The Prompt Company - AI Chatbot Demo
          </h1>
        </div>
        <button onClick={toggleKeyModal} className="text-gray-800">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>
      {showWelcomePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h2 className="text-xl font-bold mb-4">The Prompt Company의 AI Chatbot 데모</h2>
            <p className="mb-4">환영합니다! AI 챗봇과 대화를 시작해보세요.</p>
            <p className="mb-2">
              자세한 정보는 <a href="https://www.theprompt.tech" target="_blank" rel="noopener noreferrer" className="text-green-600 hover:underline">www.theprompt.tech</a>에서 확인하세요.
            </p>
            <p className="mb-4">
              문의: <a href="mailto:contact@theprompt.tech" className="text-green-600 hover:underline">contact@theprompt.tech</a>
            </p>
            <button
              onClick={() => setShowWelcomePopup(false)}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-150 ease-in-out"
            >
              시작하기
            </button>
          </div>
        </div>
      )}
      {isKeyModalOpen && (
        <div className="absolute top-16 right-4 bg-white p-4 rounded shadow-md">
          <input
            type="text"
            value={key}
            onChange={handleKeyChange}
            className="w-full border rounded px-2 py-1 mb-2"
            placeholder="키를 입력하세요"
          />
          <button
            onClick={clearMessages}
            className="w-full bg-red-500 text-white rounded px-2 py-1 hover:bg-red-600 transition duration-150 ease-in-out mb-2"
          >
            채팅 내역 삭제
          </button>
          <button
            onClick={showAboutPopup}
            className="w-full bg-blue-500 text-white rounded px-2 py-1 hover:bg-blue-600 transition duration-150 ease-in-out mb-2"
          >
            About
          </button>
          <a
            href="http://example.com"
            target="_blank"
            rel="noopener noreferrer"
            className="block w-full text-center bg-green-500 text-white rounded px-2 py-1 hover:bg-green-600 transition duration-150 ease-in-out mb-2"
          >
            안내 문서 다운로드(공사중)
          </a>
          <div className="text-sm text-gray-600">
            <a href="mailto:contact@theprompt.tech" className="hover:underline">
              Email: contact@theprompt.tech
            </a>
          </div>
        </div>
      )}
      <div className="flex-1 overflow-y-auto p-4 space-y-4" ref={chatAreaRef}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.sender === 'user' ? 'justify-end' : 'justify-start'
            }`}
          >
            <div
              className={`rounded-lg p-3 max-w-xs ${
                message.sender === 'user'
                  ? 'bg-blue-300 text-gray-800'
                  : 'bg-white text-gray-800 border border-gray-200'
              } shadow-md`}
            >
              {message.sender === 'user' ? (
                <p>{message.text}</p>
              ) : (
                <ReactMarkdown components={{
                  strong: ({node, ...props}) => <span className="font-bold" {...props} />,
                  p: ({node, ...props}) => <p className="mb-2" {...props} />
                }}>
                  {message.text}
                </ReactMarkdown>
              )}
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="flex justify-center items-center my-4">
            <div className="relative">
              <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
              <div className="absolute top-0 left-0 w-16 h-16 border-t-4 border-blue-300 border-solid rounded-full animate-pulse"></div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="bg-blue-100 p-4 shadow-inner">
        <div className="flex items-center max-w-3xl mx-auto">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            className="flex-1 border rounded-full px-4 py-2 mr-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            placeholder="메시지를 입력하세요..."
          />
          <button
            onClick={() => handleSendMessage()}
            className={`bg-blue-400 text-black rounded-full px-4 py-2 font-semibold focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-150 ease-in-out ${
              inputMessage.trim() === ''
                ? 'opacity-50 cursor-not-allowed'
                : 'hover:bg-blue-500'
            }`}
            disabled={inputMessage.trim() === ''}
          >
            전송
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
